<template>
  <div class="h-[calc(100dvh)] pt-10 md:bg-[#FFFEF4] md:p-[40px] w-full">

    <div
      class="h-full px-4 md:rounded-[70px] md:max-w-[1200px] mx-auto md:bg-white md:border-2 md:border-black flex flex-col justify-center items-center pb-[96px] md:pb-0"
    >
      <div
        class="min-h-[220px] w-[270px] md:h-[160px] md:w-[600px] flex flex-col items-center justify-center relative"
      >
        <img
          src="~/assets/images/logo.png"
          class="relative z-10 h-[24px] w-[24px] md:h-[32px] md:w-[32px] mt-10"
        />
        <span class="font-serif md:text-[22px] text-primary z-10"
          >respostaCerta</span
        >
        <div
          class="hero-ball absolute top-[30px] bg-white min-w-[320px] min-h-[320px] rounded-full scale-[0.8] md:scale-100"
        >
          <img
            class="absolute h-[65px] left-[-60px] top-[60px]"
            src="~/assets/images/landing/1.png"
          />
          <img
            class="absolute h-[65px] left-[-10px] top-[-20px]"
            src="~/assets/images/landing/2.png"
          />
          <img
            class="absolute h-[70px] left-[80px] top-[-70px]"
            src="~/assets/images/landing/3.png"
          />
          <img
            class="absolute h-[70px] right-[80px] top-[-70px]"
            src="~/assets/images/landing/4.png"
          />
          <img
            class="absolute h-[65px] right-[-10px] top-[-20px]"
            src="~/assets/images/landing/5.png"
          />
          <img
            class="absolute h-[65px] right-[-60px] top-[60px]"
            src="~/assets/images/landing/6.png"
          />
          <div class="bg-white top-[160px] absolute w-[200%] -left-1/2 h-[300px]" />
        </div>
      </div>
      <div class="pt-4 md:pt-[40px] relative -top-[70px] z-10 flex flex-col items-center">
        <h1
          class="max-w-[700px] font-serif font-bold text-[26px] md:text-[48px] text-center"
        >
          O jeito rápido, eficaz e gratuito de aumentar sua nota
        </h1>
        <p
          class="max-w-[700px] text-[16px] md:text-[22px] text-center mt-[6px]"
        >
          Estude com questões ajustadas ao seu nível, receba resoluções
          detalhadas e descubra sua nota usando o TRI, assim como no ENEM.
        </p>
        <div class="max-w-[380px] w-full mt-4">
          <NuxtLink
            @click="sendPlausibleEvent('Landing - Cadastro')"
            draggable="false"
            to="/cadastrar"
          >
            <UiButton size="lg" width="full" class="mt-[30px]">
              Começar agora
            </UiButton>
          </NuxtLink>
          <NuxtLink
            @click="sendPlausibleEvent('Landing - Entrar')"
            draggable="false"
            to="/entrar"
          >
            <UiButton
              size="sm"
              width="full"
              variant="flat"
              bg-color="white"
              class="mt-[12px]"
            >
              Já tenho uma conta
            </UiButton>
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { UiButton } from "~/components/ui";
import { sendPlausibleEvent } from "~/libs/plausible";

useHead({
  title:
    "respostaCerta - O jeito rápido, eficaz e gratuito de aumentar sua nota no ENEM",
});
definePageMeta({
  layout: "empty",
});
</script>

<style lang="sass" scoped>
.hero-ball
  box-shadow: 0px -4px 20px 29px rgba(255, 230, 0, 0.1)
</style>
